@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'assets/scss/variable.module.scss';

@font-face {
  font-family: 'Muli-ExtraLight';
  src: url('../fonts/muli/Muli-ExtraLight.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-Light';
  src: url('../fonts/muli/Muli-Light.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-Regular';
  src: url('../fonts/muli/Muli-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-SemiBold';
  src: url('../fonts/muli/Muli-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-Semi-BoldItalic';
  src: url('../fonts/muli/Muli-Semi-BoldItalic.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-Bold';
  src: url('../fonts/muli/Muli-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-ExtraBold';
  src: url('../fonts/muli/Muli-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'KCDurhamBoxcar';
  src: url('../fonts/boxcar/KCDurhamBoxcar.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Labirt';
  src: url('../fonts/Labirt/Labirt.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Betha';
  src: url('../fonts/Betha/Betha.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BebasKai';
  src: url('../fonts/bebas/BebasKai.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JunkDog';
  src: url('../fonts/JunkDog/JunkDog.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ibrand';
  src: url('../fonts/Ibrand/Ibrand.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Erstoria';
  src: url('../fonts/Erstoria/Erstoria.ttf');
  font-weight: 100;
  font-style: normal;
}



:root {
  --brand-01-color: #5ac3ba;
  --brand-02-color: #378287;
  --brand-03-color: #63d6cd;
  --brand-04-color: #865554;
  --brand-06-color: #deefd0;
  --brand-07-color: #41d0c6;
  --brand-08-color: #202427;
  --brand-09-color: #95dbd5;
  --brand-10-color: #2d625d;
  --brand-11-color: #9cedd6;

  --background-main-color: #1a1a1a;
  --background-black-color: #121212;
  --background-black-color-rgb: 18, 18, 18;

  --background-dark-color: #202427;
  --background-dark-color-rgb: 32, 36, 39;

  --background-light-color: #eff0f6;
  --background-light-color-rgb: 239, 240, 247;

  // --header-bg-color: linear-gradient(
  //   90deg,
  //   rgba(48, 98, 96, 0.7) 0%,
  //   rgba(20, 32, 33, 0.7) 50%,
  //   rgba(32, 36, 39, 0.7) 70%
  // );
  --header-bg-color: rgba(18, 18, 18, 0.8)
  --header-mobile-bg-color: rgba(206, 208, 213, 0.8);

  --avatar-border-color1: #4ea19b;
  --avatar-border-color2: #304e4e;

  --dashboard-tab-color: rgba(239, 240, 247, 0.6);

  --activity-bg-color: linear-gradient(to bottom, rgba(#378287, 0.35), rgba(#202427, 0.35));
  --activity-td-color: 39, 240, 247;
  --activity-border-color: #5ac3ba;
  --activity-box-color: transparent;
  --active-btn-border-color: '#95dbd5';

  --accordion-second-row-bg: transparent;

  --radio-check-color: #95dbd5;

  --switch-01-color: 55, 130, 135;
  --switch-02-color: 12, 65, 68;

  --error-color: #a21f23;
  --success-color: #5ac3ba;

  --text-gray-800-color: #202427;
  --text-gray-800-color-rgb: 32, 36, 39;
  --text-gray-600-color: rgba(#202427, 0.8);
  --text-gray-400-color: rgba(#202427, 0.6);
  --text-gray-200-color: #eff0f6;
  --text-gray-200-color-rgb: 239, 240, 247;

  --white-color: #ffffff;
  --white-color-rgb: 255, 255, 255;
  --profile-border-color: #121212;

  --black-color: #000000;

  --outline-button-border: #5ac3ba;
  --outline-button-color: #eff0f6;

  --step-circle-mobile-color: #95dbd5;
  --step-next-mobile-color: #202427;
  --dimension-bg-color: 12, 12, 12;

  --hero-artist-color: #63d6cd;
  --twitter-btn-color1: #5ac3ba;
  --twitter-btn-color2: #2d625d;

  --notification-card-bg: rgba(var(--text-gray-200-color-rgb), 0.15);
  --dao-title-color: #95dbd5;
  --nft-detail-artist: #93bfbb;

  --dropdown-bg-color: 14, 14, 14;
  --dropdown-border-color: rgba(239, 240, 247, 0.1);
  --dropdown-menu-bg: rgba(55, 130, 135, 0.3);
  --dropdown-menu-hover-color: $text-gray-200-color;
  --dropdown-menu-hover-border: $brand-01-color;

  --wallet-green-text: #5ac3ba;
  --wallet-error-text: #deefd0;
  --wallet-active-text: #202427;

  --follow-text-color: #95dbd5;
  --follow-bg-color: linear-gradient(to bottom, rgba(#121212, 0.35), rgba(#2d625d, 0.35));
  --artist-switch-color: linear-gradient(to right, #202427, #173437);
  --artist-switch-bg: #1c2121;
  --address-btn-color: rgba(239, 240, 247, 0.6);

  --hero-bg-color : linear-gradient(to bottom, rgba(26, 26, 26, 0), rgba(26, 26, 26, 1));

  --search-category-color: #eff0f6;

  --menu-item-right-icon: #95dbd5;
  --menu-item-open-bg: #242424;
  --search-item-color: #eff0f6;

  --resource-card-title: #95dbd5;
  --nft-detail-section-bg: #2b2b2b;
  --nft-detail-section-border: rgba($text-gray-200-color, 0.1);
  --nft-detail-bg: $background-black-color;
  --nft-detail-border: rgba(239, 240, 247, 0.1);

  --alpha-area-border: #95dbd5;
  --profile-menu-item-bg: rgba(#378287, 0.3);
  --profile-menu-item-border: #5ac3ba;
  --profile-menu-item-color: #eff0f6;
  --follow-box-shadow: #95dbd5;
  --read-more-bg: rgba(18, 18, 18, 0.8);
  --modal-bg-color: rgba(18, 18, 18, 0.5);
  --datepicker-bg-color: rgba(32, 36, 39, 0.9);
  --datepicker-button-bg: rgba(32, 36, 39, 1);
  --datepicker-date-bg: rgba(32, 36, 39, 1);
  --price-input-bg: #121212;
  --step-text-color: #202427;
  --select-collection-text: #eff0f6;
  --collection-mint-progress-bg: linear-gradient(
    90deg,
    rgba(48, 88, 86, 0.5) 0%,
    rgba(20, 32, 33, 0.5) 50%,
    rgba(32, 36, 39, 0.5) 100%
  );
  --connect-wallet-text: #eff0f6;
  --connect-wallet-bg: linear-gradient(to bottom, rgba(55, 130, 135, 1), rgba(#244246, 1));
  --ellipse-btn-shadow: #95dbd5;
  --connected-wallet-bg: linear-gradient(to bottom, rgba(12, 12, 12, 1), rgba(45, 98, 93, 1));
  --mint-progress-mobile-bg: rgba(12, 12, 12, 0.5);
  --mint-progress-bg: rgba(12, 12, 12, 0.5);
  --collection-mint-left-bg: #121212;
  --stats-accordion-border: rgba(#202427, 0.1);
  --stats-accordion-normal-border: rgba(239, 240, 247, 0.1);
  --studio-title-bg: #2B2B2B;
  --resource-accordion-dropdown-bg: rgba(#fff, 0.2);
  --resource-accordion-dropdown-color: #eff0f6;
  --resource-accordion-close-color: #95dbd5;
  --chevron-up-icon-color: #95dbd5;
  --deposit-btn-bg-1: #2b4142;
  --deposit-btn-bg-2: #59bfb7;
  --wallet-box-shadow: 0px 5px 10px rgba(#9945ff, 0.2), 0px -5px 10px rgba(#14f195, 0.2);
  --wallet-btn-bg: #121212;
  --wallet-btn-hover: #14f195;
  --collection-card-border: 1px;
  --profile-card-bg: #202427;
  --mynft-outline-border: #5ac3ba;
  --mynft-outline-color: '';
  --filter-close-color: #eff0f6;
  --collection-filter-title: #95dbd5;
  --negative-btn-border: #95dbd5;
  --checkbox-border: #95dbd5;
  --checkbox-border-color: #95dbd5;
  --checkbox-bg-color: #95dbd5;
  --menu-item-right-open-icon: #95dbd5;
  --ellipse-menu-bg: #1b1b1b;
  --ellipse-report-bg: #1b1b1b;
  --mobile-serachbox-bg: #121212;
  --mobile-serach-area-bg: #313233;
  --modal-close-btn-bg: rgba(239, 240, 246, 0.2);
  --auction-input-bg: #202427;
  --see-more-border: #ffffff;
  --artist-card-bg: rgba(32, 36, 39, 0.7);
  --input-bg: rgba(255, 255, 255, 0.25);
  --mint-btn-bg-1: #95dbd5;
  --mint-btn-bg-2: #4b6e6b;
  --mint-btn-color: #202427;
  --browse-nft-blur-bg: linear-gradient(
    to top,
    rgba(32, 36, 39, 0.5) 0%,
    rgba(32, 36, 39, 0.1) 40%,
    rgba(32, 36, 39, 0.15) 60%,
    rgba(32, 36, 39, 0.5) 100%
  );
  --resource-card-default-bg: #202427;
  --home-swiper-bg: #9E9DA0;
  --explore-btn-bg-1: #5ac3ba;
  --explore-btn-bg-2: #378287;
  --home-hero-title: #eff0f6;
  --profile-user-color: #14f195;
  --single-nft-btn-bg-1: #121212;
  --single-nft-btn-bg-2: #2d625d;
  --single-nft-btn-color: #95dbd5;
  --drop-zone-bg: rgba(255, 255, 255, 0.05);
  --filter-btn-bg: #334d4b;
  --filter-btn-border: #eceef4;
  --ellipse-svg-1: #63d6cd;
  --ellipse-svg-2: #63d6cd;
  --tooltip-bg: rgba(239, 240, 247, 0.2);
  --tooltip-border: rgba(239, 240, 247, 0.1);
  --back-btn-color: #378287;
  --back-btn-border: transparent;
  --back-btn-border-width: '1';
  --deposit-icon-bg: #5ac3ba;
  --wallet-icon-bg-1: #9945ff;
  --wallet-icon-bg-2: #8752f3;
  --wallet-icon-bg-3: #5497d5;
  --wallet-icon-bg-4: #43b4ca;
  --wallet-icon-bg-5: #28e0b9;
  --wallet-icon-bg-6: #19fb9b;
  --wallet-icon-light-bg-1: #9945ff;
  --wallet-icon-light-bg-2: #8752f3;
  --wallet-icon-light-bg-3: #5497d5;
  --wallet-icon-light-bg-4: #43b4ca;
  --wallet-icon-light-bg-5: #28e0b9;
  --wallet-icon-light-bg-6: #19fb9b;
  --logout-icon-color: #95dbd5;
  --menu-open-item-border: #95dbd5;
  --activity-tr-bg: linear-gradient(to bottom, rgba(55, 130, 135, 0.35), rgba(28, 65, 68, 0.35));
  --banner-image-bg: #ffffff;
  --menu-item-activity-icon: #95dbd5;
  --card-border-color: #292929;
  --main-bg-color: #eff0f6;
  --main-icon-color: #95dbd5;
  --search-menu-icon-color: #eff0f6;
  --social-bg-color: #202427;
  --nft-border-color: rgba(239, 240, 247, 0.1);
  --address-btn-bg-color: #202427;
  --tab-bottom-color: #95dbd5;
  --top-artist-bg: linear-gradient(to bottom, rgba(149, 219, 213, 1), rgba(75, 110, 107, 1));
  --sell-nft-border: rgba(255, 255, 255, 0.1);
  --sell-nft-bg: #121212;
  --setting-input-bg: #202427;

  --follow-btn-border-mobile: rgba(239, 240, 247, 0.25);
  --search-gray-icon: rgba(239, 240, 247, 0.75);
  --logo-text-color: #ffffff;
  --logo-text-ballon: #95dbd5;
  --search-icon-color: #121212;
  --search-icon-stop-1: #202427;
  --search-icon-stop-2: #2d625d;
  --search-icon-bg: rgba(32, 36, 39, 0.75);
  --search-gray-icon-disable: rgba(255, 255, 255, 0.2);
  --profile-banner-overlay: 18, 18, 18;
  --follow-btn-color: #eff0f6;
  --follow-btn-border: #95dbd5;
  --hide-nft-border-color: #95dbd5;
  --category-chip-bg: rgba(239, 240, 247, 0.15);
  --live-auction-card: #1b1b1b;
  --accordian-header-bg: #1b1b1b;
  --accordian-area-bg: #1b1b1b;
  --secondary-button-border: #95dbd5;
  --social-share-hover: linear-gradient(to bottom, rgba(90, 195, 186, 0.3) 0%, rgba(45, 98, 93, 0.3) 100%);
  --share-icon-bg: #2B2B2B;
  --main-bg-color-rgb: 239, 240, 247;
  --alpha-area-bg: transparent;
  --report-menu-bg: rgba(18, 18, 18, 0.5);
  --stats-label-bg: #17191a;
  --category-bottom-mask: linear-gradient(to bottom, rgba(18, 18, 18, 0), rgba(18, 18, 18, 1));
  --stats-search-bg: #2b2b2b;
  --artwork-tbl-header-bg: linear-gradient(to bottom, #202427, #121212);
  --auction-card-label: #95dbd5;
  --auction-card-bg: linear-gradient(to right, #4b6e6b, #95dbd5);
  --auction-card-color: #202427;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Muli-Regular";
  background-color: #242424;
}

h1 {
  font-size: 72px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 80px;
}

h2 {
  font-size: 48px;
  font-family: 'Muli-Bold';
  letter-spacing: 0;
  line-height: 60px;
}

h3 {
  font-size: 40px;
  font-family: 'Muli-Bold';
  letter-spacing: 0;
  line-height: 60px;
}

h4 {
  font-size: 32px;
  font-family: 'Muli-Bold';
  letter-spacing: 0;
  line-height: 60px;
}

h5 {
  font-size: 24px;
  font-family: 'Muli-Bold';
  letter-spacing: 0;
  line-height: 60px;
}

h6 {
  font-size: 16px;
  font-family: 'Muli-Bold';
  letter-spacing: 0;
  line-height: 60px;
}

.star {
  margin: 0 1px;
  transition: transform 200ms;
}

.star:hover {
  transform: scale(1.1);
}

.g-container {
  max-width: 1920px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 640px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;
  }
  @media (min-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 1280px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (min-width: 1536px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (min-width: 1680px) {
    padding-left: 150px;
    padding-right: 150px;
  }
}

.g-left-dtg-container {
  max-width: 1920px;
  width: 100%;
  padding-left: 16px;
  @media (min-width: 640px) {
    padding-left: 16px;
  }
  @media (min-width: 768px) {
    padding-left: 80px;
  }
  @media (min-width: 1024px) {
    padding-left: 0px;
  }
  @media (min-width: 1280px) {
    padding-left: 100px;
  }
  @media (min-width: 1536px) {
    padding-left: 150px;
  }
  // background: var(--second-home-bg);
}

.g-left-container {
  max-width: 1920px;
  width: 100%;
  padding-left: 16px;
  @media (min-width: 640px) {
    padding-left: 16px;
  }
  @media (min-width: 768px) {
    padding-left: 80px;
  }
  @media (min-width: 1024px) {
    padding-left: 80px;
  }
  @media (min-width: 1280px) {
    padding-left: 100px;
  }
  @media (min-width: 1536px) {
    padding-left: 150px;
  }
  // background: var(--second-home-bg);
}

.g-right-dtg-container {
  max-width: 1920px;
  width: 100%;
  padding-right: 16px;
  @media (min-width: 640px) {
    padding-right: 16px;
  }
  @media (min-width: 768px) {
    padding-right: 100px;
  }
  @media (min-width: 1024px) {
    padding-right: 40px;
  }
  @media (min-width: 1280px) {
    padding-right: 120px;
  }
  @media (min-width: 1536px) {
    padding-right: 150px;
  }
  // background-color: var(--background-main-color);
}

.g-right-container {
  max-width: 1920px;
  width: 100%;
  padding-right: 16px;
  @media (min-width: 640px) {
    padding-right: 16px;
  }
  @media (min-width: 768px) {
    padding-right: 100px;
  }
  @media (min-width: 1024px) {
    padding-right: 100px;
  }
  @media (min-width: 1280px) {
    padding-right: 120px;
  }
  @media (min-width: 1536px) {
    padding-right: 150px;
  }
  // background-color: var(--background-main-color);
}

.skeletonLoader:empty {
  width: 100%;
  height: 100%;
  display: block;
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color) !important;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes skeletonAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.05;
  }
  100% {
    opacity: 0.2;
  }
}

#heroSwiper .swiper .swiper-slide {
  transform: scale(0.9);
  transition: all 0.4s;
}
#heroSwiper .swiper .swiper-slide-active {
  transform: scale(1);
  transition: all 0.4s;
}
#heroSwiper .swiper-wrapper {
  padding-bottom: 30px;
}
#heroSwiper .swiper-horizontal > .swiper-pagination-bullets,
#heroSwiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 18px;
  z-index: 10;
  @media (min-width: 1024px) {
    left: -10px;
    bottom: 0px;
  }
}

#heroSwiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--text-gray-200-color);
  @media (max-width: 640px) {
    width: 4px;
    height: 4px;
    border-radius: 2px;
  }
}

#heroSwiper .swiper-pagination-bullet-active {
  color: var(--white-color);
  background: var(--home-swiper-bg);
  width: 35px;
  height: 8px;
  border-radius: 4px;
  @media (max-width: 640px) {
    width: 18px;
    height: 4px;
    border-radius: 2px;
  }
}
.heroNavigationSwiper {
  .swiper-button-prev {
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    top: 45%;
    left: 60px;
    @media (min-width: 1440px) {
      left: 160px;
    }
    right: 55px;
    background: var(--create-nft-card-bg);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 100;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }

  .swiper-button-next {
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    top: 45%;
    bottom: 0px;
    right: 60px;
    @media (min-width: 1440px) {
      right: 160px;
    }
    background: var(--create-nft-card-bg);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 100;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }
}


#heroSwiperMobile .swiper-wrapper {
  // padding-bottom: 30px;
}
#heroSwiperMobile .swiper-horizontal > .swiper-pagination-bullets,
#heroSwiperMobile .swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 50px;
  left: 0px;
  width: 100%;
  height: 18px;
  z-index: 10;
  @media (min-width: 414px) {
    bottom: 30px;
  }
  @media (min-width: 1024px) {
    left: -10px;
    bottom: 0px;
  }
}
#heroSwiperMobile .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--main-bg-color);
  @media (max-width: 640px) {
    width: 4px;
    height: 4px;
    border-radius: 2px;
  }
}
#heroSwiperMobile .swiper-pagination-bullet-active {
  color: var(--white-color);
  background: #9e9da0;
  width: 35px;
  height: 8px;
  border-radius: 4px;
  @media (max-width: 640px) {
    width: 18px;
    height: 4px;
    border-radius: 2px;
  }
}

#trendArtistMobile .swiper-wrapper {
  padding-bottom: 30px;
}
#trendArtistMobile .swiper-horizontal > .swiper-pagination-bullets,
#trendArtistMobile .swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 18px;
  z-index: 10;
  @media (min-width: 414px) {
    bottom: 0px;
  }
  @media (min-width: 1024px) {
    left: -10px;
    bottom: 0px;
  }
}
#trendArtistMobile .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--text-gray-200-color);
  @media (max-width: 640px) {
    width: 4px;
    height: 4px;
    border-radius: 2px;
  }
}
#trendArtistMobile .swiper-pagination-bullet-active {
  color: var(--white-color);
  background: var(--home-swiper-bg);
  width: 35px;
  height: 8px;
  border-radius: 4px;
  @media (max-width: 640px) {
    width: 18px;
    height: 4px;
    border-radius: 2px;
  }
}

// Merch Shop Card Swiper
#merchShopCardSwiper .swiper {
  overflow: visible;
}

#merchShopCardSwiper .swiper-wrapper {
  padding-bottom: 30px;
}
#merchShopCardSwiper .swiper-horizontal > .swiper-pagination-bullets,
#merchShopCardSwiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 18px;
  z-index: 10;
  @media (min-width: 414px) {
    bottom: 0px;
  }
  @media (min-width: 1024px) {
    left: -10px;
    bottom: 0px;
  }
}

.heroStudioSwiper {
  .swiper-pagination {
    position: relative !important;
    margin-top: 30px;
  }
}

.heroMobileStudioSwiper{
  .swiper-pagination {
    position: relative !important;
    margin-top: 30px;
  }
}

.swiper-pagination {
  position: relative !important;
  @media (max-width : 1024px) {
    margin-top: -20px;
  }
}



.noScrollNavigationSwiper {
  padding-bottom: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }
}

.auctionNavigationSwiper {
  padding-bottom: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  @media (min-width: 640px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .swiper-button-prev {
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    top: 45%;
    left: 15px;
    right: 55px;
    background: var(--create-nft-card-bg);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 100;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }

  .swiper-button-next {
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    top: 45%;
    bottom: 0px;
    right: 15px;
    background: var(--create-nft-card-bg);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 100;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }
}

.artistNavigationSwiper {
  padding-bottom: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  .swiper-button-prev {
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    top: 40%;
    left: 20px;
    right: 55px;
    background: var(--scroll-arrow-bg-color);
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 10;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }

  .swiper-button-next {
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    top: 40%;
    bottom: 0px;
    right: 20px;
    background: var(--scroll-arrow-bg-color);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 10;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }
}

.listingNavigationSwiper {
  padding-bottom: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  .swiper-button-prev {
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    top: 45%;
    left: 20px;
    right: 55px;
    background: var(--scroll-arrow-bg-color);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 10;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }

  .swiper-button-next {
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    top: 45%;
    bottom: 0px;
    right: 20px;
    background: var(--scroll-arrow-bg-color);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 10;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }
}

.navigationSwiper {
  padding-bottom: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  // @media (min-width: 1024px) {
  //   padding-bottom: 80px !important;
  // }
  .swiper-button-prev {
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    top: 45%;
    left: 20px;
    right: 55px;
    background: var(--scroll-arrow-bg-color);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 10;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }

  .swiper-button-next {
    display: flex;
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    border: solid 1px rgba(var(--main-bg-color-rgb), .25);
    top: 45%;
    bottom: 0px;
    right: 20px;
    background: var(--scroll-arrow-bg-color);
    box-shadow: 0px 0px 10px var(--scroll-arrow-drop-shadow);
    color: var(--scroll-arrow-color);
    &::after {
      font-size: 20px;
      font-weight: 700;
    }
    z-index: 10;
    opacity: 1 !important;
    &:hover {
      cursor: pointer;
      border: solid 1px var(--main-icon-color);
      color: var(--main-icon-color);
    }
    pointer-events: visible !important;
  }
}

.hideNavigationSwiper {
  padding-bottom: 30px !important;
  @media (min-width: 1024px) {
    padding-bottom: 80px !important;
  }
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }
}

.showNavigationSwiper {
  padding-bottom: 30px !important;
  @media (min-width: 1024px) {
    padding-bottom: 80px !important;
  }
  .swiper-button-prev {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 0px;
    right: 55px;
    color: var(--text-gray-200-color);
    opacity: 0.7;
    &::after {
      font-size: 40px;
      font-weight: 700;
    }
  }

  .swiper-button-next {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 0px;
    right: 0px;
    color: var(--text-gray-200-color);
    opacity: 0.7;
    &::after {
      font-size: 40px;
      font-weight: 700;
    }
  }
}

// scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  background-color: var(--scroll-bar-bg);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-thumb);
  border-radius: 4px;
}

// ####################################################
// ArtworksTable
#ArtworksTable {
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0 19px;
}

#ArtworksTable th {
  border-style: solid none solid none;
  padding: 4px;
  height: 30px;
  font-size: 9px;
  @media (min-width: 640px) {
    font-size: 10px;
  }
  @media (min-width: 768px) {
    font-size: 12px;
  }
  @media (min-width: 1024px) {
    height: 25px;
    font-size: 14px;
  }
  @media (min-width: 1536px) {
    font-size: 16px;
  }
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  font-family: 'Muli-Regular';
  font-weight: 400;
}

#ArtworksTable th:first-child {
  padding-left: 45px;
}

#ArtworksTable tbody:before {
  content: '@';
  display: block;
  line-height: 7px;
  text-indent: -99999px;
}

#ArtworksTable tbody tr:hover {
  color: var(--text-gray-200-color);
}

#ArtworksTable tbody tr {
  color: var(--artwork-table-tr);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  background: var(--background-main-color) !important;
}

#ArtworksTable td {
  border-style: solid none solid none;
  padding-left: 4px;
  padding-right: 4px;
  height: 70px;
  background-color: transparent;
  font-family: 'Muli-SemiBold';
  font-size: 9px;
  @media (min-width: 640px) {
    font-size: 10px;
  }
  @media (min-width: 768px) {
    font-size: 12px;
  }
  @media (min-width: 1024px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
    height: 80px;
  }
  
  line-height: 24px;
  letter-spacing: 0;
}

#ArtworksTable tbody tr {
  &:hover {
    background: rgba(var(--main-bg-color-rgb), 0.05);
  }
}

#ArtworksTable tr td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-left-style: solid;
  padding-left: 40px 
}

#ArtworksTable tr td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-right-style: solid;
}


// ####################################################
// MerchTable
#MerchTable {
  margin-top: 10px;
  // border-collapse: separate;
  border-spacing: 10px 0px;
}

// ##########################################
// skeleton

.skeletonLoaderBasic:empty {
  display: block;
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color) !important;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.others {
    height: 453px;
  }

  &.prod-name {
    height: 20px;
  }

  &.prod-description {
    width: 80%;
    height: calc(100% - 30px);
  }
}

@keyframes skeletonAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.05;
  }
  100% {
    opacity: 0.2;
  }
}

// ####################################################
// startAuctionDatePicker

.startAuctionDatePicker {
  .react-datepicker {
    // background: var(--datepicker-bg-color);
    background: var(--background-main-color);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
    border-radius: 9px;
    width: 100%;
    max-width: 432px;
    height: auto;
    font-family: 'Muli-Bold';
    font-size: 16px;
    .react-datepicker__month-container {
      width: 100%;
    }
    padding: 12px;
    @media (min-width: 1024px) {
      padding: 20px 27px 12px 27px;
    }
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__navigation {
      width: 36px;
      height: 36px;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        @media (min-width: 1024px) {
          background-color: #707070;
        }
      }
    }
    .react-datepicker__navigation--previous {
      left: auto;
      top: 20px;
      right: 58px;
      align-items: center;
      background-image: url('../../assets/icons/arrow-left-white-01.svg');
      background-repeat: no-repeat;
      background-position: center;
      -webkit-tap-highlight-color: transparent;
      @media (min-width: 1024px) {
        right: 98px;
      }
      &:hover {
        background-image: url('../../assets/icons/arrow-left-black-01.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      .react-datepicker__navigation-icon::before {
        border-width: 0px;
        width: 15px;
        height: 12px;
        transform: rotate(0deg);
      }
    }
    .react-datepicker__navigation--next {
      left: auto;
      top: 20px;
      right: 20px;
      @media (min-width: 1024px) {
        right: 31px;
      }
      background-image: url('../../assets/icons/arrow-right-white-01.svg');
      background-repeat: no-repeat;
      background-position: center;
      -webkit-tap-highlight-color: transparent;
      &:hover {
          background-image: url('../../assets/icons/arrow-right-black-01.svg');
          background-repeat: no-repeat;
          background-position: center;

      }
      .react-datepicker__navigation-icon::before {
        border-width: 0px;
        width: 15px;
        height: 12px;
        transform: rotate(0deg);
      }
    }
  }
  .react-datepicker__header {
    background: transparent;
    border-bottom: none;
    .react-datepicker__current-month {
      color: var(--text-gray-200-color);
      text-align: start;
      font-size: 22px;
      line-height: 1;
    }
    .react-datepicker__day-names {
      color: var(--main-icon-color);
      font-family: 'Muli-SemiBold';
      font-size: 14px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .react-datepicker__day-name {
        margin: 0px;
        width: 35px;
        @media (min-width: 1024px) {
          width: 45px;
        }
        justify-content: center;
        color: var(--main-icon-color);

        // color: var(--brand-09-color);
      }
    }
  }
  .react-datepicker__month {
    margin: 0px;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    gap: 3px;
    @media (min-width: 1024px) {
      margin-top: 8px;
      gap: 15px;
    }
  }
  .react-datepicker__day {
    color: var(--text-gray-200-color);
    width: 35px;
    height: 35px;
    font-family: 'Muli-Regular';
    font-size: 16px;
    @media (min-width: 1024px) {
      width: 40px;
      height: 40px;
    }
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    &:hover {
      border-radius: 9px;
      color: var(--background-dark-color);
    }
  }
  .react-datepicker__day--disabled {
    color: rgba(var(--text-gray-200-color-rgb), 0.5);
    width: 35px;
    height: 35px;
    font-family: 'Muli-Regular';
    font-size: 16px;
    @media (min-width: 1024px) {
      width: 40px;
      height: 40px;
    }
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    &:hover {
      border-radius: 9px;
      color: var(--background-dark-color);
    }
  }
  .react-datepicker__day--selected {
    background: var(--datepicker-date-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border-radius: 9px;
      color: var(--background-dark-color);
    }
  }
  .react-datepicker__day--keyboard-selected {
    background: linear-gradient(to bottom, rgba(var(--brand-02-color), 50%), rgba(#1c4144, 35%));
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border-radius: 9px;
      color: var(--background-dark-color);
    }
  }
  .react-datepicker__day--outside-month {
    color: rgba(var(--text-gray-200-color-rgb), 0.5);
  }

  .react-datepicker__input-time-container {
    margin: 10px 0px 0px 0px;
    background: rgba(var(--main-bg-color-rgb), 0.05);
    border-radius: 9px;
  }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    width: 100%;
  }
  .react-datepicker-time__input {
    width: 100%;
    margin-left: 0px !important;
    height: 40px;
    border-radius: 9px;
    input[type='time']::-webkit-calendar-picker-indicator {
      cursor: pointer;
      background: url('../../assets/icons/timer-gray.svg') no-repeat;
      padding-right: 15px;
      outline: none;
    }
    input[type='time'] {
      color: var(--text-gray-200-color);
      outline: none;
    }
  }
}

[data-name='color-1a-ff'] {
  --fill-color: var(--time-slog-bg);
}

[data-name='foster-enhanced-logo'] {
  --fill-color: var(--background-light-color);
}

[data-name='foster-enhanced-logo-mobile'] {
  --fill-color: var(--background-light-color);
}

[data-name='logo-text-01'] {
  --fill-color: var(--logo-text-color);
  [data-fill='a'] {
    fill: var(--logo-text-ballon);
  }
  [data-fill='b'] {
    fill: var(--logo-text-bottom);
  }
}

[data-name='solrplex-icon'] {
  --fill-color: var(--active-btn-border-color);
  [data-fill='a'] {
    fill: var(--auction-input-bg);
  }
}

[data-name='solrplex-icon-white'] {
  --fill-color: var(--main-bg-color);
  [data-fill='a'] {
    fill: var(--social-icon-bg);
  }
}

[data-name='ellipse-effect'] {
  [offset='0'] {
    stop-color: var(--ellipse-svg-1);
  }
  [offset='1'] {
    stop-color: var(--ellipse-svg-2);
  }
}

[data-name='right-icon'] {
  fill: var(--menu-item-right-icon);
}

[data-name='black-white-color'] {
  fill: var(--collection-card-bg);
}

[data-name='gradient-btn-icon'] {
  fill: var(--gradient-btn-color);
}

[data-name='down-icon'] {
  fill: var(--menu-item-right-open-icon);
}

[data-name='pagination-down-icon'] {
  fill: var(--pagination-down-icon);
}

[data-name='info-gray-small'] {
  fill: var(--main-icon-color);
}

[data-name='merch-badge-bg'] {
  fill: var(--background-main-color);
  stroke: rgba(var(--main-bg-color-rgb), 0.1);
}

[data-name='small-merch-badge-bg'] {
  fill: rgba(var(--main-bg-color-rgb), 0.05);
}

[data-name='diamond-bg'] {
  fill: rgba(var(--bg-F3F2F0-202427), 0.05);
}


[data-name='activity-down-icon'] {
  fill: var(--main-icon-color);
}

[data-name='stats-btn-icon'] {
  fill: var(--stats-btn-icon-color);
}

[data-name='solana-logo-dark'] {
  fill: var(--single-nft-btn-color);
}

[data-name='clock-sand-icon'] {
  fill: var(--bg-95dbd5-707070);
}

[data-name='foster-logo-dark'] {
  fill: var(--chevron-up-icon-color);
}

[data-name='copy-alt-dark'] {
  fill: var(--single-nft-btn-color);
}

[data-name='wallet-icon'] {
  fill: var(--single-nft-btn-color);
}

[data-name='menu-item-icon'] {
  fill: var(--main-icon-color);
}

[data-name='merch-toggle-icon'] {
  fill: var(--main-icon-color);
  [data-fill='a'] {
    fill: var(--mobile-serachbox-bg);
  }
}

[data-name='info-gray'] {
  fill: var(--bg-B8B9BD-707070)
}

[data-name='merch-toggle-disabled'] {
  fill: rgba(var(--main-bg-color-rgb), 0.1);
  stroke: var(--merch-disabled-icon-bg);
  [data-fill='a'] {
    fill: var(--merch-disabled-icon-color);
  }
}

[data-name='menu-item-merch'] {
  fill: var(--main-icon-color);
  stroke: var(--main-icon-color);
}

[data-name='home-menu-icon'] {
  fill: var(--secondary-button-border);
}

[data-name='edit-edition'] {
  fill: rgba(var(--main-bg-color-rgb), 0.1);
  [data-fill='a'] {
    stroke: var(--main-bg-color);
  }
}

[data-name='menu-item-merch-hover'] {
  fill: var(--secondary-button-border);
  stroke: var(--secondary-button-border);
}

[data-name='search-icon'] {
  fill: var(--search-menu-icon-color);
}

[data-name='social-icon'] {
  fill: var(--main-icon-color);
}

[data-name='checked-icon'] {
  fill: var(--main-icon-color);
  [data-fill='a'] {
    fill: var(--bg-2B2F32-FFF);
  }
}

[data-name='file-copy-icon'] {
  fill: var(--main-icon-color);
}

[data-name='deposit-disable-icon'] {
  fill: var(--deposit-disabled-color);
}

[data-name='search-gray'] {
  fill: var(--search-gray-icon);
}

[data-name='share-icon'] {
  fill: var(--main-bg-color);
  [data-fill='a'] {
    fill: var(--main-bg-color);
  }
}

[data-name='studio-ballon-icon'] {
  fill: var(--main-icon-color);
  [data-fill='a'] {
    fill: var(--main-bg-color);
  }
}

[data-name='scroll-arrow'] {
  fill: var(--scroll-arrow-color)
}

[data-name='stepper'] {
  fill: var(--step-circle-mobile-color);
  [data-fill='a'] {
    fill: var(--step-text-color);
  }
}

[data-name='notification-icon'] {
  fill: var(--stats-bg-color);
  [data-fill='a'] {
    fill: var(--main-bg-color);
  }
}

[data-name='select-collection'] {
  stroke: var(--main-icon-color);
}

[data-name='main-color-stroke'] {
  stroke: var(--main-bg-color);
}

[data-name='main-color-icon'] {
  fill: var(--main-bg-color);
}

[data-name='main-color-icon-70'] {
  fill: rgba(var(--main-bg-color-rgb), 0.7);
}

[data-name='close-icon'] {
  fill: var(--main-bg-color);
  [data-fill='a'] {
    fill: rgba(var(--main-bg-color-rgb), 0.05);
  }
}

[data-name='button-divider'] {
  fill: var(--bg-1A1A1A-4A4D50)
}

[data-name='hover-button-divider'] {
  fill: var(--bg-4D6A67-484B4E)
}

[data-name='second-button-divider'] {
  fill: var(--bg-59595B-BEBFBD)
}

[data-name='button-icon'] {
  fill: var(--bg-1A1A1A-FFF)
}

[data-name='button-icon-hover'] {
  fill: var(--bg-95dbd5-FFF)
}

[data-name='second-button-icon'] {
  fill: rgba(var(--main-bg-color-rgb), 0.8)
}

[data-name='fullscreen-icon'] {
  fill: rgba(var(--main-bg-color-rgb), 0.05);
  [data-fill='a'] {
    fill: var(--main-bg-color);
  }
}

[data-name='main-stroke-icon'] {
  stroke: var(--main-bg-color);
}

[data-name='left-arrow-icon'] {
  stroke: var(--main-bg-color);
  stroke-width: 2px;
}


[data-name='quick-buy-icon'] {
  fill: var(--quick-buy-icon-color);
}

[data-name='hide-icon'] {
  fill: #EFF0F6;
  &:hover {
    fill: #95dbd5;
  }
}

[data-name='nft-share-icon'] {
  fill: var(--bg-eff0f6-707070)
}

svg {
  fill: var(--fill-color);
}
